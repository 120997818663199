@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/sf-pro-display/SFPRODISPLAYREGULAR.woff') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/sf-pro-display/SFPRODISPLAYREGULAR.woff') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/sf-pro-display/SFPRODISPLAYBOLD.woff') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/sf-pro-display/SFPRODISPLAYBOLD.woff') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/sf-pro-display/SFPRODISPLAYBOLD.woff') format('woff2');
  font-weight: 800;
  font-style: normal;
}
