:root {
  --white-color: #fff;
  --black-color: #000;
  --light-dark-color: #333333;
  --dark-color: #1a1a1a;

  --light-purple-color: #f3f2ff;
  --light-secondary-purple-color: #e7e6fe;
  --medium-purple-color: #8681fc;
  --purple-color: #6e68fb;
  --dark-purple-color: #564ffb;
  --super-dark-purple-color: #6e68fb33;

  --extra-light-gray-color: #dcdcdc;
  --super-light-gray-color: #cccccc;
  --light-border-color: #e7e6fe;
  --light-gray-color: #808080;
  --dark-gray-color: #666666;
  --medium-gray-color: #999999;
  --dark-black-color: #0d0d0d;

  --dark-100-color: #e6e6e6;
  --dark-300-color: #b3b3b3;
  --blue-50-color: #f3f2ff;
  --blue-900-color: #251cfa;

  --light-theme-background: #fafafa;
  --dark-theme-background: var(--black-color);

  --milky-background-light: #dee2e6;

  --light-green-hover-button: #f0fdfa;
  --light-green-color: #d1fae5;
  --medium-green-color: #10b981;
  --dark-green-color: #065f46;
  --medium-dark-cyan: #0d9488;
  --medium-light-cyan: #6ee7b7;
  --mid-green-cyan: #34d399;
  --dark-green-button: #134e4a;

  --light-orange-color: #ffedd5;
  --medium-orange-color: #fbbf24;
  --hard-orange-color: #f97316;
  --dark-orange-color: #fb923c33;

  --light-blue-color: #f0f9ff;
  --expiration-blue-border: #cffafe;
  --medium-blue-color: #0095f8;
  --dark-blue-color: #003a60;

  --red-alert: #dc2626;

  --header-milky-light-bg: rgba(255, 255, 255, 0.6);
  --header-milky-dark-bg: rgba(0, 0, 0, 0.6);
  --header-mobile-bottom-border-light: 0 1px 3px rgb(0 0 0 / 10%);
  --header-mobile-bottom-border-dark: 0 1px 3px rgb(255 255 255 / 10%);

  --bank-card-shandow-box-light: 3px 2px 6px #dedede;
  --bank-card-shadow-box-dark: none;

  --side-bar-light-shadow: 5px 0 10px #e8e8e8;
  --side-bar-border-right-light: none;
  --side-bar-dark-shadow: none;
  --side-bar-light-icon: var(--black-color) !important;
  --side-bar-border-right-dark: var(--light-dark-color);
  --side-bar-dark-icon: invert(100%) sepia(0%) saturate(4369%) hue-rotate(55deg) brightness(107%) contrast(116%) !important;

  --mobile-bottom-nav-text-dark: #b6b6b6;
  --mobile-bottom-nav-icon-light: var(--block-color) !important;
  --mobile-bottom-nav-icon-dark: invert(100%) sepia(0%) saturate(4369%) hue-rotate(90deg) brightness(133%)
    contrast(161%) !important;

  --user-side-bar-head-section-light: #f7f7f7;
  --user-side-bar-head-section-dark: var(--black-color);
}

/* Light theme styles */
.light-theme {
  --theme-background: var(--light-theme-background);
  --theme-text-color: var(--black-color);
  --theme-secondary-text-color: var(--light-gray-color);
  --header-milky-bg: var(--header-milky-light-bg);
  --header-mobile-bottom-border: var(--header-mobile-bottom-border-light);
  --mobile-bottom-navigation-text: var(--dark-gray-color);
  --mobile-bottom-nav-icon: var(--mobile-bottom-nav-icon-light);
  --side-bar-bg: Var(--white-color);
  --side-bar-border-right: var(--side-bar-border-right-light);
  --side-bar-shadow-box: var(--side-bar-light-shadow);
  --side-bar-icon: var(--side-bar-light-icon);
  --user-side-bar-head-section: var(--user-side-bar-head-section-light);
  --section-title-color: var(--black-color);
  --block-background: var(--white-color);
  --block-border: var(--light-border-color);
  --inside-block: var(--white-color);
  --bank-connection-block-bg: var(--light-border-color);
  --bank-connection-border: var(--medium-purple-color);
  --carousel-widget-bg: var(--light-border-color);
  --stepper-circles-bg: var(--light-purple-color);
  --finance-check-btn-bg: var(--light-purple-color);
  --switch-toggler: var(--light-gray-color);
  --chart-numbers: var(--dark-gray-color);
  --input-color: var(--black-color);
  --input-background: var(--white-color);
  --input-border-color: var(--light-border-color);
  --drag-zone-bg: var(--light-purple-color);
  --purple-badge-bg: var(--white-color);
  --transaction-hover-bg: var(--light-purple-color);
  --transaction-category-bg: var(--light-purple-color);
  --transaction-amount-bg: #f2f2f2;
  --transaction-tag-inactive-border-color: var(--dark-100-color);
  --transaction-tag-active-color: var(--purple-color);
  --transaction-tag-button-foreground-color: var(--dark-300-color);
  --transaction-tag-collapse-button-foreground-color: var(--dark-300-color);
  --transaction-tag-collapse-button-background-color: var(--blue-50-color);
  --transactio-green-amount-bg: var(--light-green-color);
  --bank-card-shadow-box: var(--bank-card-shandow-box-light);
  --purple-expiration-bg: var(--light-border-color);
  --purple-expiration-border: var(--medium-purple-color);
  --purple-expiration-icon: var(--purple-color);
  --purple-expiration-bank: var(--purple-color);
  --orange-expiration-bg: var(--light-orange-color);
  --orange-expiration-border: var(--medium-orange-color);
  --orange-expiration-icon: var(--medium-orange-color);
  --orange-expiration-bank: var(--medium-orange-color);
  --blue-expiration-bg: var(--light-blue-color);
  --blue-expiration-border: var(--expiration-blue-border);
  --blue-expiration-icon: var(--medium-blue-color);
  --blue-expiration-bank: var(--medium-blue-color);
  --green-rating-bg: var(--light-green-color);
  --green-ration-text: var(--medium-green-color);
  --advisor-cloud-msg: var(--light-purple-color);
  --system-cloud-msg: var(--dark-100-color);
  --vinhub-option-border: var(--white-color);
  --vinhub-advisor-cloud: var(--white-color);
  --vinhub-client-cloud: var(--light-purple-color);
  --attachment-block-bg: var(--light-purple-color);
  --attachment-block-border: var(--purple-color);
  --attachment-text: var(--purple-color);
  --contract-icon-bg: var(--white-color);
  --milky-background: var(--milky-background-light);
  --disabled-form-input: var(--light-theme-background);
  --progress-bar-bg: var(--light-purple-color);
  --surplus-income: #fcd34d;
  --surplus-outcome: var(--purple-color);
  --hover-btn-bg: var(--white-color);
  --detected-contract-card-bg: var(--light-purple-color);
  --navigation-tabs-bg: var(--light-secondary-purple-color);

  --wealth-card-bg: var(--light-green-color);
  --wealth-card-bg-border: var(--medium-light-cyan);
  --wealth-card-button: var(--medium-dark-cyan);
  --wealth-card-total-asset-text: var(--medium-gray-color);
  --wealth-card-button-hover-bg: var(--light-green-hover-button);
  --wealth-card-button-hover-text: var(--wealth-card-button);
  --wealth-category-filter-text: var(--dark-gray-color);

  --image-upload-bg: var(--light-purple-color);
  --3d-button-shadow: 0px 3px 20.4px 0px rgba(0, 0, 0, 0.12);
  --3d-button-background: radial-gradient(
      rgb(255 255 255),
      rgb(255 255 255 / 18%) 13.35%,
      rgb(255 255 255 / 0%) 49.51%,
      rgb(202 202 202 / 11%) 84.73%,
      rgb(255 255 255 / 53%) 100%
    )
    /* ... other light theme styles ..*/;
}

/* Dark theme styles */
.dark-theme {
  --theme-background: var(--dark-theme-background);
  --theme-text-color: var(--white-color);
  --theme-secondary-text-color: var(--super-light-gray-color);
  --header-milky-bg: var(--header-milky-dark-bg);
  --header-mobile-bottom-border: var(--header-mobile-bottom-border-dark);
  --mobile-bottom-navigation-text: var(--mobile-bottom-nav-text-dark);
  --mobile-bottom-nav-icon: var(--mobile-bottom-nav-icon-dark);
  --side-bar-bg: Var(--dark-black-color);
  --side-bar-border-right: var(--side-bar-border-right-dark);
  --side-bar-shadow-box: var(--side-bar-dark-shadow);
  --side-bar-icon: var(--side-bar-dark-icon);
  --user-side-bar-head-section: var(--user-side-bar-head-section-dark);
  --section-title-color: var(--white-color);
  --block-background: var(--dark-color);
  --block-border: var(--light-dark-color);
  --inside-block: var(--black-color);
  --bank-connection-block-bg: var(--dark-color);
  --bank-connection-border: var(--dark-gray-color);
  --carousel-widget-bg: var(--dark-color);
  --stepper-circles-bg: var(--black-color);
  --finance-check-btn-bg: var(--black-color);
  --switch-toggler: var(--black-color);
  --chart-numbers: var(--white-color);
  --input-color: var(--white-color);
  --input-background: var(--black-color);
  --input-border-color: var(--light-dark-color);
  --drag-zone-bg: var(--black-color);
  --purple-badge-bg: var(--black-color);

  --transaction-hover-bg: var(--light-dark-color);
  --transaction-category-bg: var(--light-dark-color);
  --transaction-amount-bg: var(--dark-black-color);
  --transaction-tag-inactive-border-color: var(--dark-300-color);
  --transaction-tag-button-foreground-color: var(--dark-300-color);
  --transaction-tag-active-color: var(--purple-color);
  --transaction-tag-collapse-button-foreground-color: white;
  --transaction-tag-collapse-button-background-color: var(--purple-color);
  --transactio-green-amount-bg: var(--dark-green-color);

  --bank-card-shadow-box: var(--bank-card-shadow-box-dark);
  --purple-expiration-bg: var(--super-dark-purple-color);
  --purple-expiration-border: var(--medium-purple-color);
  --purple-expiration-icon: var(--white-color);
  --purple-expiration-bank: var(--purple-color);
  --orange-expiration-bg: var(--dark-orange-color);
  --orange-expiration-border: var(--medium-orange-color);
  --orange-expiration-icon: var(--white-color);
  --orange-expiration-bank: var(--medium-orange-color);
  --blue-expiration-bg: var(--dark-blue-color);
  --blue-expiration-border: var(--medium-blue-color);
  --blue-expiration-icon: var(--white-color);
  --blue-expiration-bank: var(--medium-blue-color);
  --green-rating-bg: var(--dark-green-color);
  --green-ration-text: var(--light-green-color);
  --advisor-cloud-msg: var(--black-color);
  --system-cloud-msg: var(--light-gray-color);
  --vinhub-option-border: var(--light-dark-color);
  --vinhub-advisor-cloud: var(--light-dark-color);
  --vinhub-client-cloud: var(--purple-color);
  --attachment-block-bg: var(--dark-color);
  --attachment-block-border: var(--white-color);
  --attachment-text: var(--white-color);
  --contract-icon-bg: var(--extra-light-gray-color);
  --milky-background: var(--dark-black-color);
  --disabled-form-input: var(--light-dark-color);
  --progress-bar-bg: var(--black-color);
  --surplus-income: #9b8217;
  --surplus-outcome: #4642a6;
  --hover-btn-bg: var(--light-dark-color);
  --detected-contract-card-bg: var(--black-color);
  --navigation-tabs-bg: var(--light-dark-color);

  /* ... wealth dark theme styles ... */
  --wealth-card-bg: var(--medium-dark-cyan);
  --wealth-card-bg-border: var(--dark-green-button);
  --wealth-card-button: var(--dark-green-button);
  --wealth-card-total-asset-text: var(--white-color);
  --wealth-card-button-hover-bg: var(--dark-green-color);
  --wealth-card-button-hover-text: var(--light-border-color);
  --wealth-category-filter-text: var(--white-color);

  --image-upload-bg: var(--dark-color);
  --3d-button-shadow: 0 3px 20.4px rgba(0, 0, 0, 0.25);
  --3d-button-background: radial-gradient(
    rgb(0 0 0),
    rgb(0 0 0 / 18%) 13.35%,
    rgb(0 0 0 / 0%) 49.51%,
    rgb(171 171 171 / 11%) 84.73%,
    rgba(255, 255, 255, 0.53) 100%
  );
  /* ... other dark theme styles ... */

  /* Date picker dark theme */
  --asset-badge-border-color: #35353a;
  --asset-badge-text-color: #e6e6e6;
  --asset-badge-subtitle-color: #b3b3b3;
  --asset-preview-title-color: var(--asset-badge-text-color);

  .bs-datepicker .bs-datepicker-body {
    background-color: black !important;
    border: 1px solid var(--block-border) !important;
  }

  .skeleton-loader {
    background: rgb(61 61 61) no-repeat !important;
  }
}
